import React, {useEffect, useState} from "react";

import Like from "./Rating/Like";
import President from "./Rating/President";
import LimitedLike from "./Rating/LimitedLike";
import Star from "./Rating/Star";
import Eurovision from "./Rating/Eurovision";

export default function Rating({type, id, value, onUpdateRating, onShowOptions}) {

    let component = null

    switch (type) {
        case 'like':
            component = <Like
                            id={id}
                            key={id}
                            value={value}
                            onUpdateRating={onUpdateRating}
                        />
            break

        case 'limited-like':
            component = <LimitedLike
                            id={id}
                            key={id}
                            value={value}
                            onUpdateRating={onUpdateRating}
                        />
            break

        case 'president':
            component = <President
                            id={id}
                            key={id}
                            value={value}
                            onUpdateRating={onUpdateRating}
                        />
            break

        case 'star':
            component = <Star
                            id={id}
                            key={id}
                            value={value}
                            onUpdateRating={onUpdateRating}
                            onShowOptions={onShowOptions}
                        />
            break

        case 'eurovision':
            component = <Eurovision
                id={id}
                key={id}
                value={value}
                onUpdateRating={onUpdateRating}
                onShowOptions={onShowOptions}
            />
            break

    }

    return ( component )
}
