import React, {Component, useState, useEffect, useRef} from "react";
import './App.css';
import Vimeo from "@u-wave/react-vimeo";
import Playlist from "./Playlist";
import axios from "axios";
import {useParams} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import Rated from "./Rated";

let {REACT_APP_VIDEO_API, REACT_APP_RATING_TYPE, REACT_APP_RATING_API} = process.env;
let uuid = localStorage.getItem('uuid');

if ( !uuid) {
    uuid = uuidv4()
    localStorage.setItem('uuid', uuid)
}

function App() {

  const plRef = useRef(null)

  const [videosLoading, setVideosLoading] = useState(1)
  const [ratingsLoading, setRatingsLoading] = useState(1)
  const [videos, setVideos] = useState([])
  const [ratings, setRatings] = useState([])
  const [playingVideoId, setPlayingVideoId] = useState( videos[0] === undefined || videos.length == 0 ? null : videos[0].id )
  const [playingVideoUrl, setPlayingVideoUrl] = useState(playingVideoId)
  const [reloading, setReloading] = useState(false)

  const {instance, game} = useParams();

  useEffect(() => {

      const fetchVideos = async () => {
          console.log('FETCHING VIDEOS!!!');
          try {
              const result = await axios.get(REACT_APP_VIDEO_API, {
                  responseType: 'json',
                  method: 'get',
                  cors: false,
                  transformResponse: (data) => {
                       return JSON.parse(data).map((dataItem) => {

                          if ( dataItem.mp_data.instance == instance ) {

                              return {
                                  id: dataItem.vimeo_data.uri.slice(8),
                                  title: dataItem.mp_data.name,
                                  url: dataItem.vimeo_data.link,
                                  game: dataItem.mp_data.game,
                                  missionId: dataItem.mp_data.mission_id,
                                  inputName: dataItem.mp_data.input_name,
                                  inputType: dataItem.mp_data.input_type,
                                  thumbnail: dataItem.vimeo_data.pictures.sizes[0].link,
                                  rating: null,
                                  current: dataItem.mp_data.game === game ? 1 : 0
                              }

                          }
                      }).sort((elA, elB) =>
                           elA.title.toLowerCase().localeCompare(elB.title.toLowerCase())
                      )

                  }
              })

              let currentIndex = result.data.findIndex(item => item.current === 1)

              let finalItems = []
              if (currentIndex === -1) {
                  finalItems = result.data;
              } else {

                  for(let i = currentIndex; i < result.data.length; i++) {
                      finalItems.push(result.data[i])
                  }

                  for(let i = 0; i < currentIndex; i++) {
                      finalItems.push(result.data[i])
                  }
              }

              setVideos(finalItems)

              if ( result.data.length ) {
                  setPlayingVideoId(result.data[0].id)
              }

          } catch (error) {
              console.error(error)
          }
          setVideosLoading(false)
      }

      const fetchRatings = async () => {

          console.log("FETCHING RATINGS!!!");
          let ratingLink = REACT_APP_RATING_API + '/' + instance + '/' + game + '/' + uuid + '/' + REACT_APP_RATING_TYPE;

          const ratings = await axios.get(ratingLink, {
              responseType: 'json',
              method: 'get',
              cors: false,

          })

          setRatings(ratings.data)
          setRatingsLoading(false)
      }

       fetchVideos()
       fetchRatings()

  }, [])

  const mergeVideoRatings = async () => {
        if (videos && ratings && videos.length && ratings.length) {

            videos.forEach((video, vidIndex, videos) => {
                let ratIndex = ratings.findIndex(rat => rat.video_id === video.id)
                if (ratIndex > -1) {
                    videos[vidIndex].rating = ratings[ratIndex].value
                } else {
                    videos[vidIndex].rating = null
                }
            })

        }
    }

  useEffect(() => { mergeVideoRatings() }, [videos, ratings])

  useEffect(() => {
        let vid = videos.find(el => el.id === playingVideoId)
        if (vid) {
            let alink = vid.url.substr(18).split("/")

            if ( alink.length == 1 ) {
                setPlayingVideoUrl(vid.url)
            } else {
                setPlayingVideoUrl("https://player.vimeo.com/video/" + alink[0] + "?h=" + alink[1] + '&autoplay=true');
            }

        }
  }, [playingVideoId])

  function endPlayingHandler() {
      let playingVideoIndex = videos.findIndex(video => video.id === playingVideoId)
      if ( playingVideoIndex == videos.length - 1 ) {
          setPlayingVideoId(videos[0].id)
      } else {
          setPlayingVideoId(videos[playingVideoIndex+1].id)
      }
  }

  function updateVideos(vids){
      setVideos(vids)
      setReloading(!reloading)
  }

  function upRating(id, value){
      let rated = videos.filter(vid => vid.rating > 0).sort((elA, elB) => elB.rating - elA.rating)
      let currIndex = rated.findIndex(vid => vid.id === id)

      if (currIndex === -1) {
          return false
      }

      if (!rated[currIndex-1]) {
          return false
      }

      const tmpRating = rated[currIndex-1]
      plRef.current(id, tmpRating.rating, true)
      plRef.current(tmpRating.id, value, true)

  }

  function downRating(id, value){
      let rated = videos.filter(vid => vid.rating > 0).sort((elA, elB) => elB.rating - elA.rating)
      let currIndex = rated.findIndex(vid => vid.id === id)

      if (currIndex === -1) {
          return false
      }

      if (!rated[currIndex+1]) {
          return false
      }

      const tmpRating = rated[currIndex+1]
      plRef.current(id, tmpRating.rating, true)
      plRef.current(tmpRating.id, value, true)
  }

  function cancelRating(id){
      plRef.current(id, 0)
      setReloading(!reloading)
  }

  return (
    <div className="App">
          {
              (videosLoading || ratingsLoading) && <div>Loading...</div>
          }
          {
              (!videosLoading && !ratingsLoading) &&
                  (
                    <React.Fragment>
                        <div className="row m-0 p-0">
                            <div className="col p-0 pe-2">
                                <Vimeo video={playingVideoUrl}
                                       autoplay={true}
                                       controls={false}
                                       showPortrait={false}
                                       showTitle={false}
                                       responsive={false}
                                       onEnd={endPlayingHandler}
                                       onError={error => {console.log(error)} }
                                       className=""
                                />
                            </div>
                            {
                                REACT_APP_RATING_TYPE === "like"
                                    ?
                                    null
                                    :
                                    (
                                        <div className="col m-0 p-0">
                                            <Rated
                                                type={REACT_APP_RATING_TYPE}
                                                videos={videos}
                                                upRating={upRating}
                                                downRating={downRating}
                                                cancelRating={cancelRating}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                        <Playlist
                                  plRef={plRef}
                                  videos={videos}
                                  ratings={ratings}
                                  instance={instance}
                                  game={game}
                                  playingVideoId={playingVideoId}
                                  play={videoId => {setPlayingVideoId(videoId)}}
                                  onUpdateVideos={updateVideos}
                        />
                    </React.Fragment>
              )
          }
    </div>
  );

}

export default App;
