import React from "react";

const Validator = (type, videos) => {
    function validate() {

        videos.forEach((video) => {
            video.rating++
        })

        return videos;
    }

    return validate()
}

export default Validator;
