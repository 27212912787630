import React, {useEffect, useState} from "react";
import {Button, CloseButton} from "react-bootstrap";

const Eurovision = ({id, value, onUpdateRating, onShowOptions}) => {

    function show(){
        onShowOptions(id, value)
    }

    function cancel(){
        onUpdateRating(id, 0)
    }

    return (
        <div className="row">
            <div className="col-10 row">
                <Button onClick={show} >
                    {
                        value
                            ?
                            (value)
                            :
                            'Unrated'
                    }
                </Button>
            </div>
            <div className="col-2">
            {
                value
                    ?
                    <CloseButton onClick={cancel} />
                    :
                    null
            }
            </div>

        </div>

    )
}

export default Eurovision;
