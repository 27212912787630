const Eurovision = () => {
    const options = [
        1, 2, 3, 4, 5, 6, 7, 8, 10, 12
    ]

    return (options)
}

const options = [
    1, 2, 3, 4, 5, 6, 7, 8, 10, 12
]

export default options;
