import React from "react";

export default function President({id, onUpdateRating, value}){

    function updateStatus() {
        onUpdateRating(id, !value);
    }

    return (
        <button onClick={updateStatus} >
        {
            value ? <b>Yes</b> : <i>No</i>
        }
        </button>
    )
}
