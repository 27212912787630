const LimitedLike = ({id, onUpdateRating, value}) => {

    return (
        <button onClick={() => onUpdateRating(id, !value)} >
            { value
                ?
                <b>Unlike</b>
                :
                <i>Like</i>
            }
        </button>
    )
}

export default LimitedLike;
