import React, {useEffect, useState, useRef} from "react";
import PlaylistItem from "./PlaylistItem";
import Validator from "./Validator";
import {Overlay, Popover, Button, Modal} from "react-bootstrap";
import { default as EurovisionValidator} from "./Validator/Eurovision";

let {REACT_APP_RATING_LIMIT, REACT_APP_RATING_TYPE} = process.env

export default function Playlist({plRef, instance, game, play, videos, ratings, playingVideoId, onUpdateVideos}) {

    const [conflictItem, setConflictItem] = useState(false)
    const [avOptions, setAvOptions] = useState([])
    const [isPlaying, setIsPlaying] = useState(playingVideoId)
    const [validating, setValidating] = useState(false)
    const [initialize, setInitiallize] = useState(true)
    const [show, setShow] = useState(false)
    const [videoRatingId, setVideoRatingId] = useState(false)
    const [videoRatingValue, setVideoRatingValue] = useState(0)
    const [reload, setReload] = useState(false)
    const [availableOptions, setAvailableOptions] = useState(EurovisionValidator)
    const [showModal, setShowModal] = useState(false)

    const target = useRef(null);

    useEffect(() => {
        plRef.current = updateRatingHandler
        getAvOptions()
    }, [])
    useEffect(() => {}, [reload, availableOptions])

    function saveRating(e){
        updateRatingHandler(videoRatingId, e.target.value)
        setShow(false)
    }

    function saveDialogRating(id, value){
        updateRatingHandler(id, value, false)
        setShowModal(false)
        setReload(!reload)
    }

    const updateVideos = (videos) => {

        //console.log("Playlist:UpdateVideos")
        //videos.forEach((video) => console.log('video', video.id, video.title, video.rating))

        let pl = []

        if (videos.length === 0) {
            return
        }

        videos.forEach((video, index) => {

            if (video !== undefined) {
                let rating = null;
                if (initialize) {
                    rating = ratings ? ratings.find(element => element.video_id == video.id) : null
                    rating = rating ? rating.value : null
                } else {
                    rating = video.rating
                }
                pl.push(<PlaylistItem
                            video={video}
                            key={video.id}
                            id={video.id}
                            index={index}
                            isPlaying={playingVideoId}
                            instance={instance}
                            game={game}
                            rating={rating}
                            onValidate={validate}
                            onUpdateRating={updateRatingHandler}
                            onShowOptions={showOptionsHandler}
                            isPlayingHandler={
                                e => {
                                    setIsPlaying(video.id)
                                    play(video.id)
                                }
                            }
                        />
                )

            }

            return null
        })

        return pl
    }

    function updateRatingHandler(id, value, splice = false) {

        let isValid = validate(REACT_APP_RATING_TYPE, id, value, splice)

        if (!isValid) {
            console.error("INVALID!!!")
            return false
        }

        let video = videos ? videos.find(vid => vid.id == id) : null
        let mission_id, input_name, input_type, video_game;
        if (video) {
                mission_id = video.missionId;
                input_name = video.inputName;
                input_type = video.inputType;
                video_game = video.game;
        }

        let postData = {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: REACT_APP_RATING_TYPE,
                video_id: id,
                video_game: video_game,
                user: localStorage.getItem("uuid"),
                game: game,
                instance: instance,
                mission_id: mission_id,
                input_name: input_name,
                input_type: input_type,
                value: Number(value)
            })
        }

        fetch(process.env.REACT_APP_RATING_API, postData)
            .then(data => {
                //console.log("data after post:", data)
            })

        setReload(!reload)
        return true

    }

    function validate(type, id, value, splice){

        //let validatedVideos = Validator(type, videos)

        setInitiallize(false)

        if (value > 0) {
            if (type == "president") {
                console.log("PRESIDENT")
                videos.forEach((video, index) => {
                    if (video.id != id) {
                        video.rating = 0
                    } else {
                        video.rating = value
                    }
                })
            } else if (type == "limited-like") {
                console.log("LIMITED LIKES")
                let selectedVideos = videos.filter(vid => vid.rating > 0)

                if (selectedVideos && selectedVideos.length >= REACT_APP_RATING_LIMIT) {
                    console.log("TOO MUCH")
                    alert("TOO Many likes. You are allowed to like maximum " + REACT_APP_RATING_LIMIT + " videos")
                    return false
                } else {
                    let vidIndex = videos.findIndex(vid => vid.id === id)

                    if (vidIndex > -1) {
                        videos[vidIndex].rating = value
                    }
                }
            } else if (type == "star" || type == "eurovision") {

                let selectedVideos = videos.filter(vid => vid.rating == value)

                if (selectedVideos.length > 0 && !splice) {
                    setConflictItem(selectedVideos[0])
                    setShowModal(id)
                    getAvOptions(id)
                    setReload(!reload)

                    return false
                }

                let vidIndex = videos.findIndex(vid => vid.id === id)

                if (vidIndex > -1) {
                    videos[vidIndex].rating = value
                }
            }
        } else {
           let vidIndex = videos.findIndex(vid => vid.id === id)
           if (vidIndex > 0) {
               videos[vidIndex].rating = 0
           }
        }

        onUpdateVideos(videos)
        setReload(!reload)

        return true;
    }

    function showOptionsHandler(id, value){
        setVideoRatingId(id)
        setVideoRatingValue(value)

        let availableRatingOptions = []
        let usedOptions = []
        const usedRatings = videos.filter( vi => vi.rating )

        if ( usedRatings && usedRatings.length ) {
            usedOptions = usedRatings.map(vid => vid.rating)
        }

        availableRatingOptions = availableOptions.filter(aOpt => !usedOptions.includes(aOpt))

        console.log("Available rating Options:", availableRatingOptions)

        setAvailableOptions(availableRatingOptions)

        setShow(!show)
    }

    var playlist = updateVideos(videos)

    // TODO:
    let optionsList = []
    if (EurovisionValidator) {
        for( let i = 0; i < EurovisionValidator.length; i++) {
            let disabled = !availableOptions.includes(EurovisionValidator[i])
            optionsList.push(<Button onClick={saveRating} value={EurovisionValidator[i]} disabled={disabled}>{EurovisionValidator[i]}</Button>)
        }
    }

    function getAvOptions(id=false){
        let avOps = []
        for(let i=1; i <= 5; i++) {
            let variant = 'primary'
            let disabled = false
            if (videos.findIndex(vid => vid.rating == i) !== -1) {
                variant = 'success';
                disabled = true;
            } else {
                avOps.push(<Button
                    key={i}
                    variant={variant}
                    disabled={disabled}
                    onClick={() => {
                        saveDialogRating(id, i)
                    }} value={i} className="col-2 p-0 mx-1">{i}</Button>)
            }
        }

        setAvOptions(avOps)
    }

    return (
        <div className="playlist bg-light table-light mt-2" id="playlist">
            <h4 className="p-1 border-bottom">Playlist</h4>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                dialogClassName="modal-90w"
                centered={true}
                aria-labelledby="custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="custom-modal-styling-title">
                        You already gave {conflictItem.rating} points to {conflictItem.title}.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        avOptions.length
                        ?
                            <>
                                <p>Please choose from available options</p>
                                {avOptions}
                            </>
                            :
                            <p>Please use Rated box in order to free up votes</p>

                    }

                </Modal.Body>
            </Modal>

            {validating && <div>Validating...</div>}
            {
                ( !validating && playlist && playlist.length > 0)
                ?
                    <div className="" style={{height: '500px'}}>
                        <Overlay target={target.current} show={show} placement="right-start">
                            {(props) => (
                                <Popover id="popover-basic" style={{maxWidth:'auto'}} >
                                    <Popover.Header as="h3">Give points to video #{}</Popover.Header>
                                    <Popover.Body>
                                        {optionsList}
                                    </Popover.Body>
                                </Popover>
                            )}
                        </Overlay>
                        {playlist}
                    </div>
                :
                    <p>No videos</p>
            }
        </div>
    )
}
