import React, {useEffect, useState} from "react";
import {Button, CloseButton} from "react-bootstrap";
import {BsStar, BsStarFill} from "react-icons";

const Star = ({id, value, onUpdateRating, onShowOptions}) => {

    function save(e){
        //onShowOptions(id, value)
        onUpdateRating(id, e.target.value)
    }

    function cancel(){
        onUpdateRating(id, 0)
    }


    let v1 = 'primary';
    let v2 = 'primary';
    let v3 = 'primary';
    let v4 = 'primary';
    let v5 = 'primary';

    switch (Number(value)){
        case 1:
            v1 = "success"
        break;

        case 2:
            v1 = "success"
            v2 = "success"
            break;

       case 3:
            v1 = "success"
            v2 = "success"
            v3 = "success"
            break;

       case 4:
            v1 = "success"
            v2 = "success"
            v3 = "success"
            v4 = "success"
            break;

       case 5:
            v1 = "success"
            v2 = "success"
            v3 = "success"
            v4 = "success"
            v5 = "success"
            break;

    }


    return (
        <div className="row">
            <Button
                variant={v1}
                onClick={save} value={1} className="col-2 p-0 mx-0">1</Button>
            <Button
                variant={v2}
                onClick={save} value={2} className="col-2 p-0 mx-1">2</Button>
            <Button
                variant={v3}
                onClick={save} value={3} className="col-2 p-0 mx-0">3</Button>
            <Button
                variant={v4}
                onClick={save} value={4} className="col-2 p-0 mx-1">4</Button>
            <Button
                variant={v5}
                onClick={save} value={5} className="col-2 p-0 mx-0 me-1">5</Button>

            <Button onClick={cancel} className="btn-close col-1 my-1 mb-1 p-0" aria-label="Close">

            </Button>

        </div>

    )
}

export default Star;
