import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const Rated = ({type, videos, upRating, downRating, cancelRating}) => {

    const [reload, setReload] = useState(false)

    useEffect(() => {
        console.log("Rated Reload")
    }, [reload])

    let rated = []
    if (videos.length) {
        rated = videos.filter(vid => vid.rating > 0).sort((elA, elB) => elB.rating - elA.rating)
    }

    function cancel(e){
        cancelRating(e.target.id, 0)
    }

    function up(id, rating){
        upRating(id, rating)
        setReload(!reload)
    }

    function down(id, rating){
        downRating(id, rating)
        setReload(!reload)
    }

    return (

        <div className="bg-light table-striped">
            <h4 className="p-1 border-bottom">Rated</h4>
            {
                rated.map((item, index, items) => (
                    <div className="row m-0 mt-2" key={item.id}>
                        {
                            type === 'star' || type === 'eurovision'
                            ?
                                (
                                    <div className="col-1 h-100 ps-5">
                                    {
                                        index === 0
                                            ?
                                            <div className="h-100 pb-4"></div>
                                            :
                                                <BsChevronUp
                                                    role="button"
                                                    className="align-top"
                                                    onClick={() => up(item.id, item.rating)}
                                                />
                                    }

                                    {
                                        index === items.length-1
                                            ?
                                            <div className="mt-5"></div>
                                            :
                                                <BsChevronDown
                                                    role="button"
                                                    className="align-bottom mt-4"
                                                    onClick={() => down(item.id, item.rating)}
                                                />
                                    }
                                    </div>
                                )
                                :
                                null
                        }

                        <div className="col"><img src={item.thumbnail} height="66" /></div>
                        <div className="col">{item.title}</div>
                        <div className="col">{item.rating}</div>
                        <div className="col">
                            <Button id={item.id} onClick={cancel} className="btn-close col-1 my-1 mb-1 p-0" aria-label="Close"></Button>
                        </div>
                    </div>
                ))
            }
        </div>

    )
}

export default Rated;
