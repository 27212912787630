import React, {useEffect, useState} from "react";
import Rating from "./Rating";
import {Image} from "react-bootstrap";

let { REACT_APP_RATING_TYPE} = process.env;

export default function PlaylistItem({
                             video,
                             isPlaying,
                             isPlayingHandler,
                             index,
                             instance,
                             game,
                             rating,
                             onValidate,
                             onUpdateRating,
                             onShowOptions
                        }) {

    let rowBg = isPlaying === video.id ? 'bg-secondary mx-0 px-1' : 'bg-transparent mx-0 px-1';

    rowBg += " row py-1";

    return (
        <div className={rowBg} >
            <div className="col-1 pt-2">{index+1}</div>
            <div className="col-2">
                <button video={video.url} id={video.id} onClick={isPlayingHandler}
                    className="bg-transparent border-0" >
                    <img src={video.thumbnail} height="40"/>
                </button>
            </div>
            <div className="col-5 text-start text-wrap">
                <button video={video.url} id={video.id} onClick={isPlayingHandler}
                        className="nav-link text-nowrap border-0 bg-transparent align-middle w-100" >
                    {video.title}
                </button>
            </div>
            <div className="col-4 pt-2">
                {
                    ( video.current === 1 )
                    ?
                        <div>Own</div>
                    :
                        <Rating
                            type={REACT_APP_RATING_TYPE}
                            value={rating}
                            id={video.id}
                            onUpdateRating={onUpdateRating}
                            onShowOptions={onShowOptions}
                        />
                }
            </div>
        </div>
    )
}
