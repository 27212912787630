import React, {useEffect, useState} from "react";

const Like = ({id, onUpdateRating, value}) => {

    const [liked, setLiked] = useState(value)

    function updateStatus() {
        onUpdateRating(id, !liked)
        setLiked(!liked)
    }

    return (
        <button onClick={updateStatus} >
            { liked
            ?
                <b>Unlike</b>
            :
                <i>Like</i>
            }
        </button>
    )
}

export default Like;
