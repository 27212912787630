import React from 'react';
import {ReactDOM, render} from 'react-dom';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />} >
              <Route path=":instance" element={<App />} >
                  <Route path="/:instance/:game" element={<App />} />
              </Route>
          </Route>
      </Routes>

  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
